.auth-main {
    height: 100vh;
    background-color: #eaeaea;
  }
  
  .auth-content {
    left: 50%;
    padding: 10vmin;
    position: fixed;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  
  .auth-card {
    background-color: #ffffff;
    box-shadow: 3px 3px 8px #888888;
    border-radius: 4px;
    width: 400px;
    min-width: 300px;
    text-align: center;
  }
  .auth-logo {
    width: 118px;
    margin-bottom: -10px;
  }
  .auth-form {
    margin-top: 35px;
  }
  .auth-input-field {
    font-size: 18px;
    margin-bottom: 25px;
  }