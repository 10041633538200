.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid slategray;
  border-bottom: 1px solid slategray;
}
.ReactTable .rt-thead.-headerGroups {
  background: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
/* .ReactTable .rt-theader .rt-tr {
  background-color: cadetblue;
} */
.ReactTable .rt-thead .rt-tr {
  background-color: cadetblue;
}
